<template>
  <div>
    <b-container id="project-container">
      <b-row align-h="center" align-v="center" align-content="center">
        <b-col align-self="center" class="text-center">
          <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

          <h1 class="project-title">
            {{ project.title }}
          </h1>

          <div class="project-bloc-info mt-4">
            <p>
              Date de début : {{ formatDate(project.beginDate) }}
              -
              Date de fin : {{ formatDate(project.endDate) }}
            </p>
            <p>
              Nombre d'ateliers : {{ workshopsLength }}
            </p>
            <p>
              Durée total des ateliers :
              <br>
              {{ totalWorkshopsPlannedDuration }}mins (prévue)
              -
              {{ totalWorkshopsActualDuration }}mins (réelle)
            </p>
          </div>

          <b-button v-b-modal.create-new-workshop-modal variant="primary" class="mt-4 ml-1">
            Nouvel atelier
          </b-button>

          <b-container id="project-workshops-container" class="mt-5">
            <b-row
              align-h="start" 
              align-v="center" 
              align-content="center"
              cols="3"
            >
              <b-col
                v-for="(workshop, index) in workshops"
                :key="index"
                align-self="center"
                class="p-1"
              >
                <WorkshopCard
                  :workshop="workshop"
                  :workshopNumber="index + 1"
                  showDeleteBtn
                  @cardClicked="goToWorkshop(workshop)"
                  @workshopDeleted="fetchProject()"
                ></WorkshopCard>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center" align-content="center">
        <b-col align-self="center"> 
          <b-modal
            id="create-new-workshop-modal"
            ref="modal"
            title="Créer un nouvel atelier"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            centered
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                label="Titre"
                label-for="title-input"
                invalid-feedback="Le titre est requis"
                :state="workshopTitleState"
              >
                <b-form-input
                  id="title-input"
                  v-model="workshopTitle"
                  :state="workshopTitleState"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Date de l'atelier"
                label-for="event-date-input"
                invalid-feedback="La date de l'atelier est requise"
                :state="workshopEventDateState"
              >
                <b-form-input 
                  id="event-date-input"
                  type="datetime-local"
                  v-model="workshopEventDate"
                  :state="workshopEventDateState"
                  placeholder="Choisissez une date"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Lieu de l'atelier"
                label-for="location-input"
              >
                <b-form-input
                  id="location-input"
                  v-model="workshopLocation"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Durée prévue (mins)"
                label-for="planned-duration-input"
                invalid-feedback="La durée prévue est requise"
                :state="workshopPlannedDurationState"
              >
                <b-form-input
                  id="planned-duration-input"
                  v-model="workshopPlannedDuration"
                  type="number"
                  :state="workshopPlannedDurationState"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Couleur"
                label-for="color-input"
                invalid-feedback="La couleur est requise"
                :state="workshopColorState"
              >
                <b-form-row>
                  <b-col>
                    <b-form-select
                      id="color-input"
                      v-model="workshopColor"
                      :state="workshopColorState"
                      :options="colors"
                      required
                    ></b-form-select>
                  </b-col>
                  <b-col>
                      <div :style="`height:100%;background:${workshopColor}`"></div>
                  </b-col>
                </b-form-row>
              </b-form-group>
            </form>

            <template #modal-footer="{ok, cancel}">
              <b-button @click="cancel()">
                Retour
              </b-button>
              <b-button variant="primary" @click="ok()">
                Valider
              </b-button>
            </template>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { fetchProject, createWorkshop } from '@/helpers/apiService'
import WorkshopCard from '@/components/WorkshopCard.vue'

export default {
  name: 'project',
  components: { WorkshopCard },
  data() {
    return {
      project: {},
      workshops: [],
      workshopTitle: '',
      workshopTitleState: null,
      workshopEventDate: '',
      workshopEventDateState: null,
      workshopLocation: '',
      workshopPlannedDuration: 0,
      workshopPlannedDurationState: null,
      workshopColor: '#FFFFFF',
      workshopColorState: null,
      colors: [
        { text: 'Blanc', value: '#FFFFFF' },
        { text: 'Vert', value: '#B0E5C6' },
        { text: 'Gris', value: '#CCCCCC' },
        { text: 'Orange', value: '#FFAD43' },
        // { text: 'Rouge', value: '#EB8FA6' },
        // { text: 'Jaune', value: '#FFF1DA' },
        // { text: 'Violet', value: '#B991D9' },
      ],
    }
  },
  mounted() {
    this.$emit('changeBackgroundColor', 'white')
    this.$emit('changeTextColor', 'black')

    this.$router.onReady(() => {
      this.fetchProject()
    })
  },
  computed: {
    breadcrumbItems() {
      let projectTitle = this.project ? this.project.title : ''

      return [
        {
          text: 'Liste des projets',
          to: { name: 'projects-list' },
        },
        {
          text: projectTitle,
          active: true,
        },
      ]
    },
    workshopsLength() {
      return this.workshops.length
    },
    totalWorkshopsPlannedDuration() {
      let result = 0

      this.workshops.forEach(workshop => {
        if (workshop.plannedDuration) {
          result += workshop.plannedDuration
        }
      });

      return result
    },
    totalWorkshopsActualDuration() {
      let result = 0

      this.workshops.forEach(workshop => {
        if (workshop.actualDuration) {
          result += workshop.actualDuration
        }
      });

      return result
    },
  },
  methods: {
    async fetchProject() {
      let res = await fetchProject(this.$route.params.projectId)

      this.project = res
      this.workshops = res.workshops
    },
    async createNewWorkshop() {
      const workshop = {
        project: this.project.id,
        title: this.workshopTitle,
        eventDate: new Date(this.workshopEventDate),
        location: this.workshopLocation,
        plannedDuration: this.workshopPlannedDuration,
        color: this.workshopColor,
        content: [
          { title: "Meeting minutes", content: "" },
          { title: "Notes", content: "" },
          { title: "Actions", content: "" },
        ],
      }

      createWorkshop(workshop)
    },
    formatDate(date) {
      return this.$dayjs(date).format('DD/MM/YYYY')
    },
    goToWorkshop(workshop) {
      this.$router.push({ name: 'workshop', params: { workshopId: workshop.id }})
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.workshopTitleState = valid
      this.workshopEventDateState = valid
      this.workshopPlannedDurationState = valid
      this.workshopColorState = valid

      return valid
    },
    resetModal() {
      this.workshopTitle = ''
      this.workshopTitleState = null
      this.workshopEventDate = ''
      this.workshopEventDateState = null
      this.workshopLocation = ''
      this.workshopPlannedDuration = 0
      this.workshopPlannedDurationState = null
      this.workshopColor = '#FFFFFF'
      this.workshopColorState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.createNewWorkshop()
      this.fetchProject()

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('create-new-workshop-modal')
      })
    },
  },
}
</script>

<style scoped>
.project-title {
  color: #1E97C4
}
.workshop-card {
  cursor: pointer;
  height: 240px;
}
</style>
