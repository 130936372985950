<template>
  <div>
    <b-container id="login-container">
      <b-row align-h="center" align-v="center" align-content="center">
        <b-col align-self="center" class="d-flex login-col">
          <img class="logo" src="/assets/logo.png">
        </b-col>
      </b-row>
      <b-row align-h="center" align-v="center" align-content="center">
        <b-col sm="12" md="6" align-self="center" class="d-flex login-col">
          <b-card 
            title="Se connecter" 
            class="mt-5 login-card"
            align="center"
          >
            <b-form>
              <b-form-input
                v-model="form.email"
                type="email"
                required
                id="email-input"
                placeholder="Votre e-mail"
              >
              </b-form-input>

              <b-input-group class="mt-3">
                <b-form-input
                  v-model="form.password"
                  :type="showPassword ? 'text': 'password'"
                  required
                  id="password-input"
                  placeholder="Votre mot de passe"
                />
                <b-input-group-append>
                  <b-button variant="outline-secondary" @click="toggleShow">
                    <b-icon
                      class="file-trash-icon" 
                      :icon="showPassword ? 'eye-slash' : 'eye'" 
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              
              <div class="mt-2">
                <b-button variant="primary" @click="login()">
                  Connexion
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { login } from '@/helpers/apiService'

export default {
  name: 'login',
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showPassword: false,
    };
  },
  created() {
    this.$emit('changeBackgroundColor', '#178fe6')
    this.$emit('changeTextColor', 'white')
  },
  methods: {
    login() {
      login(this.form.email, this.form.password)
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
}
</script>

<style scoped>
.login-col {
  justify-content: center;
}
.login-card {
  max-width: 40rem;
  width: 40rem;
}
.logo {
  display: block;
  margin: 0 auto;
  width: 180px;
}
</style>