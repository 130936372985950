<template>
  <div>
    <b-container id="projects-list-container">
      <b-row align-h="center" align-v="center" align-content="center">
        <b-col align-self="center">        
          <h1 class="text-center projects-list-title">
            Mes projets
          </h1>

          <b-button v-b-modal.create-new-project-modal variant="primary" class="mt-4">
            Nouveau projet
          </b-button>

          <b-table 
            striped 
            hover 
            :items="projects"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            sort-icon-left
            selectable
            @row-clicked="goToProject"
            class="mt-5"
          >
            <template #cell(actions)="row">
              <b-button 
                size="sm" 
                @click="showDeleteProjectModal(row.item)"
                variant="danger"
                class="mr-1"
              >
                Supprimer
              </b-button>
            </template>
          </b-table>

          <b-modal
            id="create-new-project-modal"
            ref="modal"
            title="Créer un nouveau projet"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
            centered
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group
                label="Titre"
                label-for="title-input"
                invalid-feedback="Le titre est requis"
                :state="projectTitleState"
              >
                <b-form-input
                  id="title-input"
                  v-model="projectTitle"
                  :state="projectTitleState"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Date de début"
                label-for="begin-date-input"
                invalid-feedback="La date de début est requise"
                :state="projectBeginDateState"
              >
                <b-form-datepicker 
                  id="begin-date-input"
                  v-model="projectBeginDate"
                  :state="projectBeginDateState"
                  placeholder="Choisissez une date"
                  required
                ></b-form-datepicker>
              </b-form-group>
              <b-form-group
                label="Date de fin"
                label-for="end-date-input"
                invalid-feedback="La date de fin est requise"
                :state="projectEndDateState"
              >
                <b-form-datepicker 
                  id="end-date-input"
                  v-model="projectEndDate"
                  :state="projectEndDateState"
                  placeholder="Choisissez une date"
                  required
                ></b-form-datepicker>
              </b-form-group>
            </form>

            <template #modal-footer="{ok, cancel}">
              <b-button @click="cancel()">
                Retour
              </b-button>
              <b-button variant="primary" @click="ok()">
                Valider
              </b-button>
            </template>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { fetchProjects, createProject, deleteProject } from '@/helpers/apiService'
import { mapState } from 'vuex'

export default {
  name: 'project-list',
  data() {
    return {
      fields: [
        {
          key: 'id',
          thClass: 'd-none', 
          tdClass: 'd-none',
        },
        {
          key: 'title',
          label: 'Titre',
          sortable: true,
        },
        {
          key: 'beginDate',
          label: 'Date de début',
          sortable: true,
        },
        {
          key: 'endDate',
          label: 'Date de fin',
          sortable: true,
        },
        { 
          key: 'actions', 
          label: 'Actions' 
        },
      ],
      sortBy: 'beginDate',
      sortDesc: false,
      projects: [],
      projectTitle: '',
      projectTitleState: null,
      projectBeginDate: '',
      projectBeginDateState: null,
      projectEndDate: '',
      projectEndDateState: null,
    }
  },
  mounted() {
    this.$emit('changeBackgroundColor', 'white')
    this.$emit('changeTextColor', 'black')

    this.$router.onReady(() => {
      this.fetchProjectsList()
    })
  },
  computed: mapState({
    user: state => state.user
  }),
  methods: {
    async fetchProjectsList() {
      let result = []
      let res = await fetchProjects()

      res.forEach(project => {
        delete project.author
        
        let beginDate = new Date(project.beginDate)
        let endDate = new Date(project.endDate)
        project.beginDate = this.$dayjs(beginDate).format('DD/MM/YYYY')
        project.endDate = this.$dayjs(endDate).format('DD/MM/YYYY')

        result.push(project)
      })

      this.projects = result
    },
    async createNewProject() {
      const project = {
        author: this.user.id,
        title: this.projectTitle,
        beginDate: this.$dayjs(this.projectBeginDate).toISOString(),
        endDate: this.$dayjs(this.projectEndDate).toISOString(),
      }

      createProject(project)
    },
    async removeProject(project) {
      deleteProject(project.id).then(() => this.fetchProjectsList())
    },
    showDeleteProjectModal(project) {
      this.$bvModal.msgBoxConfirm(`Êtes-vous sûr de vouloir supprimer le projet '${project.title}' ?`, {
        title: 'Supprimer le projet',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.removeProject(project)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    goToProject(project) {
      this.$router.push({ name: 'project', params: { projectId: project.id }})
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.projectTitleState = valid
      this.projectBeginDateState = valid
      this.projectEndDateState = valid

      return valid
    },
    resetModal() {
      this.projectTitle = ''
      this.projectTitleState = null
      this.projectBeginDate = ''
      this.projectBeginDateState = null
      this.projectEndDate = ''
      this.projectEndDateState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.createNewProject()
      this.fetchProjectsList()

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('create-new-project-modal')
      })
    },
  },
}
</script>

<style scoped>
.projects-list-title {
  color: #1E97C4
}
</style>
