<template>
    <b-card 
      class="workshop-card text-center p-2" 
      :style="'background-color: ' + workshop.color"
    >
      <template #header>
        <b-button 
          v-if="showEdit && !readOnly" 
          v-b-modal.update-workshop-modal 
          title="Modifier" 
          class="edit-btn"
        >
          <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
        </b-button>
        <b-button 
          v-if="showDeleteBtn" 
          title="Supprimer" 
          class="delete-btn"
        >
          <b-icon
            class="file-trash-icon" 
            icon="trash" 
            @click="removeWorkshop()"
          ></b-icon>
        </b-button>
      </template>

      <b-modal
        id="update-workshop-modal"
        ref="modal"
        title="Modifier l'atelier"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
        centered
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Titre"
            label-for="title-input"
            invalid-feedback="Le titre est requis"
            :state="workshopTitleState"
          >
            <b-form-input
              id="title-input"
              v-model="workshopTitle"
              :state="workshopTitleState"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Date de l'atelier"
            label-for="event-date-input"
            invalid-feedback="La date de l'atelier est requise"
            :state="workshopEventDateState"
          >
            <b-form-input 
              id="event-date-input"
              type="datetime-local"
              v-model="workshopEventDate"
              :state="workshopEventDateState"
              placeholder="Choisissez une date"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Lieu de l'atelier"
            label-for="location-input"
          >
            <b-form-input
              id="location-input"
              v-model="workshopLocation"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Durée prévue (mins)"
            label-for="planned-duration-input"
            invalid-feedback="La durée prévue est requise"
            :state="workshopPlannedDurationState"
          >
            <b-form-input
              id="planned-duration-input"
              v-model="workshopPlannedDuration"
              type="number"
              :state="workshopPlannedDurationState"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Durée réelle"
            label-for="actual-duration-input"
            :state="workshopActualDurationState"
          >
            <b-form-input
              id="actual-duration-input"
              v-model="workshopActualDuration"
              type="number"
              :state="workshopActualDurationState"
              @input="workshopColor = '#CCCCCC'"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Couleur"
            label-for="color-input"
            invalid-feedback="La couleur est requise"
            :state="workshopColorState"
          >
            <b-form-row>
              <b-col>
                <b-form-select
                  id="color-input"
                  v-model="workshopColor"
                  :state="workshopColorState"
                  :options="colors"
                  required
                ></b-form-select>
              </b-col>
              <b-col>
                  <div :style="`height:100%;background:${workshopColor}`"></div>
              </b-col>
            </b-form-row>
          </b-form-group>
        </form>

        <template #modal-footer="{ok, cancel}">
          <b-button @click="cancel()">
            Retour
          </b-button>
          <b-button variant="primary" @click="ok()">
            Valider
          </b-button>
        </template>
      </b-modal>

      <span @click="$emit('cardClicked')">
        <b-card-title>
          <!-- Atelier <span v-if="workshopNumber">{{ workshopNumber }}</span> ({{ workshop.title }}) -->
          {{ workshop.title }}
        </b-card-title>
        <b-card-text>
          {{ new Date(workshop.eventDate).toLocaleString() }}
          <br>
          {{ workshop.plannedDuration }}mins (prévue)
          <span v-if="workshop.actualDuration">
            -
            {{ workshop.actualDuration }}mins (réelle)
          </span>
          <p>
            <span v-if="workshop.location" class="d-block">Lieu : {{ workshop.location }}</span>
            <span v-if="workshop.files.length" class="d-block">{{ workshop.files.length }} fichiers</span>
          </p>
        </b-card-text>
        
        <h4 v-if="!readOnly && showShareBtn && workshop.hash">
          Lien de partage :
          <br />
          <b-badge>{{ `${currentLocation}?share=${workshop.hash}` }}</b-badge>
        </h4>

        <br />

        <b-button 
          v-if="showShareBtn && !readOnly" 
          variant="primary"
          @click="$emit('shareWorkshopClicked')"
        >
          {{ workshop.hash ? 'Générer un nouveau lien de partage' : 'Partager l\'atelier' }}
        </b-button>
      </span>
    </b-card>
</template>

<script>
import { updateWorkshop, deleteWorkshop } from '@/helpers/apiService'

export default {
  name: 'workshopCard',
  props: {
    workshop: {
        type: Object,
        default: () => {},
    },
    workshopNumber: {
        type: Number,
        default: null,
    },
    showShareBtn: {
        type: Boolean,
        default: false,
    },
    showEdit: {
        type: Boolean,
        default: false,
    },
    showDeleteBtn: {
      type: Boolean,
      default: false,
    },
    projectId: {
        type: String,
        default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
        workshopTitle: '',
        workshopTitleState: null,
        workshopEventDate: '',
        workshopEventDateState: null,
        workshopLocation: '',
        workshopPlannedDuration: 0,
        workshopPlannedDurationState: null,
        workshopActualDuration: 0,
        workshopActualDurationState: null,
        workshopColor: '',
        workshopColorState: null,
        colors: [
          { text: 'Blanc', value: '#FFFFFF' },
          { text: 'Vert', value: '#B0E5C6' },
          { text: 'Gris', value: '#CCCCCC' },
          { text: 'Orange', value: '#FFAD43' },
          // { text: 'Rouge', value: '#EB8FA6' },
          // { text: 'Jaune', value: '#FFF1DA' },
          // { text: 'Violet', value: '#B991D9' },
        ],
    }
  },
  computed: {
    currentLocation() {
      return window.location.href
    },
  },
  methods: {
    removeWorkshop() {
      this.$bvModal.msgBoxConfirm(`Êtes-vous sûr de vouloir supprimer l'atelier '${this.workshop.title}' ?`, {
        title: 'Supprimer l\'atelier',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            deleteWorkshop(this.workshop.id).then(() => this.$emit('workshopDeleted'))
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.workshopTitleState = valid
        this.workshopEventDateState = valid
        this.workshopPlannedDurationState = valid
        this.workshopActualDurationState = valid
        this.workshopColorState = valid

        return valid
    },
    resetModal() {
        const dt = this.workshop.eventDate;
        this.workshopTitle = this.workshop.title
        this.workshopTitleState = null
        // this.workshopEventDate = `${dt.toLocaleDateString()}T${dt.toLocaleTimeString().substring(0, 5)}`
        this.workshopEventDate = `${dt.substring(0, 10)}T${new Date(dt).toLocaleTimeString().substring(0, 5)}`
        this.workshopEventDateState = null
        this.workshopPlannedDuration = this.workshop.plannedDuration
        this.workshopPlannedDurationState = null
        this.workshopLocation = this.workshop.location
        this.workshopActualDuration = this.workshop.actualDuration
        this.workshopActualDurationState = null
        this.workshopColor = this.workshop.color
        this.workshopColorState = null
    },
    handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
    },
    handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
            return
        }

        console.log(this.workshopEventDate)

        const workshop = {
          project: this.projectId,
          title: this.workshopTitle,
          eventDate: new Date(this.workshopEventDate), // new date from local date
          location: this.workshopLocation,
          plannedDuration: this.workshopPlannedDuration,
          actualDuration: this.workshopActualDuration || null,
          color: this.workshopColor,
        }

        updateWorkshop(this.workshop.id, workshop).then(() => this.$emit('workshopUpdated'))

        // Hide the modal manually
        this.$nextTick(() => {
            this.$bvModal.hide('update-workshop-modal')
        })
    },
  },
}
</script>

<style scoped>
.card-header {
  padding: 0px;
  background-color: transparent;
  border-bottom: none;
  align-self: end;
}
.card-header:first-child {
  border-radius: none;
}
.edit-btn, .delete-btn {
  padding: 0.275rem 0.45rem;
}
</style>
