<template>
  <div v-if="workshop">
    <b-container id="workshop-container">
      <b-row align-h="center" align-v="center" align-content="center">
        <b-col align-self="center" class="text-center">
          <b-breadcrumb v-if="!isReadOnly" :items="breadcrumbItems"></b-breadcrumb>

          <!-- <h1 class="project-title">
            {{ project.title }}
          </h1>
          
          <div class="project-bloc-info mt-4">
            <p>
              Date de début : {{ formatDate(project.beginDate) }}
              -
              Date de fin : {{ formatDate(project.endDate) }}
            </p>
          </div> -->

          <WorkshopCard
            :workshop="workshop"
            :projectId="project.id"
            :workshopNumber="workshopNumber"
            :readOnly="isReadOnly"
            showShareBtn
            showEdit
            @workshopUpdated="fetchWorkshopAndProject"
            @shareWorkshopClicked="generateShareLink"
          ></WorkshopCard>

          <b-container id="workshop-form-container">
            <b-form class="workshop-form mt-5">

              <b-form-group
                v-for="(content, idx) in workshop.content"
                :key="`workshop-content-${idx}`"
                :label="content.title"
                label-size="lg"
              >
                <b-form-textarea
                  v-model="content.content"
                  placeholder="Votre texte..."
                  rows="4"
                  :disabled="isReadOnly"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                label="Fichiers joints"
                label-for="attached-files-input"
                label-size="lg"
              >
                <b-form-row>
                  <b-col sm="9" md="6" lg="4">
                    <b-form-file
                      id="attached-files-input"
                      v-model="attachedFilesInput"
                      placeholder="Choisisser un fichier..."
                      drop-placeholder="Glisser un fichier ici..."
                      name="uploaded_file"
                      multiple
                    ></b-form-file>
                  </b-col>
                  <b-col sm="3" md="2">
                    <b-button variant="primary" @click="uploadAttachedFiles()" :disabled="!attachedFilesInput.length">
                      Téléverser
                    </b-button>
                  </b-col>
                </b-form-row>
                <b-list-group class="mt-2">
                  <b-list-group-item
                    v-for="(file, index) in workshop.files"
                    :key="index"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span v-if="file.data">
                      <a :href="getFileSrc(file)" :download="file.name">
                        {{ file.name }}
                      </a>
                    </span>
                    <span v-else>
                      {{ file.name }}
                    </span>
                    <b-icon
                      v-if="!isReadOnly"
                      class="file-trash-icon" 
                      icon="trash" 
                      variant="danger"
                      @click="showDeleteFileModal(file)"
                    ></b-icon>
                  </b-list-group-item>
                  <b-list-group-item
                    v-if="!workshop.files.length"
                    class="d-flex justify-content-between align-items-center"
                  >
                    Aucun fichier joint
                  </b-list-group-item>
                </b-list-group>
                <b-alert
                  :show="uploadFilesInProgress"
                  variant="warning"
                  @dismissed="dismissCountDown=0"
                  class="mt-4"
                >
                  <p>Les fichiers sont en cours de téléchargement...</p>
                  <b-progress
                    variant="warning"
                    :max="5"
                    :value="0"
                    height="4px"
                  ></b-progress>
                </b-alert>
              </b-form-group>
              <b-form-group
                label="Participants"
                label-for="attendees-input"
                label-size="lg"
              >
                <b-row no-gutters v-for="(attendee, idx) in workshop.attendees" :key="`attendee-${idx}`">
                  <b-col>
                    <a v-if="!isReadOnly && !workshop.actualDuration" @click.prevent="showDeleteAttendeeModal(attendee)" href="#">
                      <b-icon class="mr-4" icon="trash" variant="danger" />
                    </a>
                    <b-form-checkbox v-model="attendee.present" class="d-inline" :disabled="isReadOnly || workshop.actualDuration">
                      {{ attendee.firstname }} {{ attendee.lastname}}
                    </b-form-checkbox>
                    <span v-if="attendee.email">
                      - {{ attendee.email }}
                      <a v-if="!isReadOnly && !workshop.actualDuration" @click.prevent="showEmailModal(attendee)" href="#">
                        <b-icon icon="envelope"  variant="primary" @click="showEmailModal(attendee)" />
                      </a>
                    </span>
                  </b-col>
                </b-row>
                <span v-if="!workshop.attendees.length">
                  - Aucun participant
                </span>
                <br />
                <b-button
                  v-if="!isReadOnly && !workshop.actualDuration"
                  @click.prevent="attendeeModal.show = true"
                  title="Ajouter" 
                  class="add-attendee-btn" 
                >
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                </b-button>
              </b-form-group>

              <div v-if="!isReadOnly" class="mt-2">
                <b-button variant="primary" @click="submitForm()">
                  Sauvegarder
                </b-button>
              </div>
            </b-form>
          </b-container>
        </b-col>
      </b-row>
    </b-container>


    <b-modal
      title="Ajouter un participant"
      v-model="attendeeModal.show"
      centered
    >
      <form ref="attendeesForm" @submit.prevent="submitAttendeesForm">
        <b-form-group label="Prénom">
          <b-form-input
            v-model="attendeeModal.form.firstname"
            required
          />
        </b-form-group>
        <b-form-group label="Nom">
          <b-form-input
            v-model="attendeeModal.form.lastname"
            required
          />
        </b-form-group>
        <b-form-group label="Email" >
          <b-form-input
            type="email"
            v-model="attendeeModal.form.email"
          />
        </b-form-group>
      </form>

      <template #modal-footer="{cancel}">
        <b-button @click="cancel()">
          Retour
        </b-button>
        <b-button variant="primary" @click="$refs.attendeesForm.requestSubmit()">
          Valider
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="emailModal.show"
      title="Envoyer l'invitation par email"
      centered
    >
      <form ref="emailForm" @submit.prevent="submitEmailForm">
        <b-form-group label="Email" >
          <textarea class="form-control" v-model="emailModal.form.content" rows="8" required />
        </b-form-group>
        L'événement ICS sera joint automatiquement à votre email
      </form>

      <template #modal-footer="{cancel}">
        <b-button @click="cancel()">
          Retour
        </b-button>
        <b-button variant="primary" @click="$refs.emailForm.requestSubmit()">
          Valider
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { fetchWorkshop, updateWorkshop, uploadFile, sendWorkshopInviteByEmail } from '@/helpers/apiService'
import cryptoRandomString from 'crypto-random-string'
import WorkshopCard from '@/components/WorkshopCard.vue'

export default {
  name: 'workshop',
  components: { WorkshopCard },
  data() {
    return {
      workshop: null,
      workshops: [],
      project: {},
      attachedFilesInput: [],
      attendeeModal: {
        show: false,
        form: {
          lastname: '',
          firstname: '',
          email: '',
        },
      },
      emailModal: {
        show: false,
        form: {
          email: "",
        }
      },
      uploadFilesInProgress: false,
      autoSaveInterval: 0,
    }
  },
  mounted() {
    this.$emit('changeBackgroundColor', 'white')
    this.$emit('changeTextColor', 'black')

    this.$router.onReady(() => {
      this.fetchWorkshopAndProject()
    })

    // Auto save every 30 sec
    this.autoSaveInterval = setInterval(() => {
      this.autoSubmitForm();
    }, 30000);
  },
  beforeDestroy() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval)
    }
  },
  computed: {
    breadcrumbItems() {
      let projectTitle = this.project ? this.project.title : ''
      let projectId = this.project ? this.project.id : ''
      let workshopTitle = this.workshop ? `Atelier (${this.workshop.title})` : ''

      return [
        {
          text: 'Liste des projets',
          to: { name: 'projects-list' }
        },
        {
          text: projectTitle,
          to: { name: 'project', params: { projectId } },
        },
        {
          text: workshopTitle,
          active: true,
        },
      ]
    },
    workshopNumber() {
      return this.project.workshops ? 
        this.project.workshops.findIndex(workshop => workshop.id == this.workshop.id) + 1 :
        null
    },
    isReadOnly() {
      let result = false
      let shareHash = this.$route.query.share

      if (shareHash && shareHash == this.workshop.hash) {
        result = true
      }

      return result
    },
  },
  methods: {
    async fetchWorkshopAndProject() {
      try {
        const workshopSharedLinkHash = this.$route.query.share ? this.$route.query.share : ''

        this.workshop = await fetchWorkshop(this.$route.params.workshopId, workshopSharedLinkHash)

        if (this.workshop.projects) {
          this.project = this.workshop.projects
        }

        // await fetchProject(this.project.id, workshopSharedLinkHash, this.$route.params.workshopId)

        // this.initForm()
      } catch(err) {
        console.error(err)
      }
    },
    generateShareLink() {
      const hash = cryptoRandomString({length: 14, type: 'url-safe'})
      updateWorkshop(this.$route.params.workshopId, { hash }).then(() => this.fetchWorkshopAndProject())
    },
    getFileSrc(file) {
      // const bytes = new Uint8Array(file.data.data)
      // const binary = bytes.reduce((data, b) => data += String.fromCharCode(b), '')
      // return `data:${file.contentType};base64,` + btoa(binary)
      return `data:${file.contentType};base64,${file.data}`
    },
    formatDate(date) {
      return this.$dayjs(date).format('DD/MM/YYYY')
    },
    autoSubmitForm() {
      let payload = { ...this.workshop }
      delete payload.id
      delete payload.files
      delete payload.projects
      delete payload.createdAt
      delete payload.updatedAt

      updateWorkshop(this.$route.params.workshopId, payload).then(() => {
        this.$toast.open({
          message: 'Sauvegarde automatique effectuée',
          type: 'success',
        })
      })
    },
    async submitForm() {
      let payload = { ...this.workshop }
      delete payload.id
      delete payload.files
      delete payload.projects
      delete payload.createdAt
      delete payload.updatedAt

      updateWorkshop(this.$route.params.workshopId, payload).then(() => {
        this.fetchWorkshopAndProject()
        this.$toast.open({
          message: 'Atelier sauvegardé',
          type: 'success',
        })
      })
    },
    async uploadAttachedFiles() {
      this.uploadFilesInProgress = true

      for (const file of this.attachedFilesInput) {
        const formData = new FormData()
        formData.append("uploadedFile", file)
        await uploadFile(formData, this.$route.params.workshopId)
      }

      this.uploadFilesInProgress = false
      this.attachedFilesInput = []
      this.$toast.open({
        message: 'Fichier téléversé',
        type: 'success',
      })
    
      this.submitForm()
    },
    removeFile(file) {
      this.workshop.files = this.workshop.files.filter(f => f.name != file.name)
      updateWorkshop(this.$route.params.workshopId, { files: this.workshop.files }).then(() => {
        this.$toast.open({
          message: 'Fichier supprimé',
          type: 'success',
        })
      })
    },
    removeAttendee(attendee) {
      this.workshop.attendees = this.workshop.attendees.filter(e => e.firstname != attendee.firstname || e.lastname != attendee.lastname);
    },
    showDeleteFileModal(file) {
      this.$bvModal.msgBoxConfirm(`Êtes-vous sûr de vouloir supprimer le fichier '${file.name}' ?`, {
        title: 'Supprimer le fichier',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.removeFile(file)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    showDeleteAttendeeModal(attendee) {
      this.$bvModal.msgBoxConfirm(`Êtes-vous sûr de vouloir supprimer le participant ${attendee.firstname} ${attendee.lastname} ?`, {
        title: 'Supprimer le participant',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.removeAttendee(attendee)
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    showEmailModal(attendee) {
      console.log(attendee);
      const dt = new Date(this.workshop.eventDate)
      const dtStr = dt.toLocaleString([], {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute:'2-digit'
      }).replace(', ', ' à ')

      this.emailModal.show = true;
      const email = `Bonjour ${attendee.firstname} ${attendee.lastname},\n\nVous êtes invité à l'atelier « ${this.workshop.title} » le ${dtStr}. La durée prévue de l'atelier est de ${this.workshop.plannedDuration} minutes.\n\nA bientôt !\n\n`

      this.emailModal.form.to = attendee.email
      this.emailModal.form.subject = `Invitation à l'atelier ${this.workshop.title}, ${new Date(this.workshop.eventDate).toLocaleString()}`
      this.emailModal.form.content = email
    },
    submitEmailForm() {
      sendWorkshopInviteByEmail(this.workshop.id, this.emailModal.form)
        .then(() => {
          this.$toast.open({
            message: 'Email envoyé',
            type: 'success',
          })
          this.emailModal.show = false
        })
    },
    submitAttendeesForm() {
      // Exit when the form isn't valid
      this.workshop.attendees.push({
        firstname: this.attendeeModal.form.firstname,
        lastname: this.attendeeModal.form.lastname,
        email: this.attendeeModal.form.email,
        present: false,
      })

      this.attendeeModal.form = { firstname: '', lastname: '', email: '' }
      
      // Hide the modal manually
      this.attendeeModal.show = false
    },
  },
}
</script>

<style scoped>
.project-title {
  color: #1E97C4
}
#workshop-form-container {
  display: flex;
  justify-content: center;
}
.workshop-form {
  width: 100%;
}
.workshop-form .form-group {
  text-align: start;
}
.add-attendee-btn {
  padding: 0.275rem 0.45rem;
}
.file-trash-icon {
  cursor: pointer;
}
.attendees-remove-icon-list {
  list-style-type: none;
}
</style>
